<template lang="html">
	<section class="block">
		<h3>{{ $t(`${sector}.${abast}.materials.title`) }}</h3>
		<i18n
			v-if="isReportEditable"
			:path="`${sector}.${abast}.materials.desc`"
			tag="p"
			class="note">
			<a
				href="https://www.ipcc-nggip.iges.or.jp/EFDB/find_ef.php"
				target="blank">
				{{ $t(`${sector}.${abast}.materials.ipcc_db`) }}
			</a>
		</i18n>
		<emissions-table v-if="!skip" v-model="emissions" :editable="isReportEditable">
			<template v-slot:header>
				<th>{{ $t('report.table.concept') }}</th>
				<th>{{ $t('report.table.emission_factor') }}</th>
				<th>{{ $t('report.table.qty') }}</th>
			</template>
			<template v-slot:body="{ item }">
				<td>{{ item.resource.label }}</td>
				<td>
					{{ Math.round(item.emissions / item.qty * 1000) / 1000 }}
					<em class="unit">{{ `kgCO₂/${item.resource.unit}` }}</em>
				</td>
				<td>{{ item.qty }}<em class="unit">{{ item.resource.unit }}</em></td>
			</template>
			<template v-slot:insert="{ insert }">
				<td>
					<input
						type="text"
						class="long"
						v-model="insert.concept"
						:placeholder="$t(`${sector}.${abast}.materials.example`)" />
				</td>
				<td>
					<input
						v-model.number="insert.resource.emission"
						:placeholder="insert.resource.unit
							? `kgCO₂/${insert.resource.unit}`
							: $t('report.table.emission_factor')"
						type="number"
						min="0"
						step="0.001"
						class="long" />
					<em class="unit">kgCO₂ / </em>
					<dropdown-select
						v-model="insert.resource.unit"
						:options="units"
						:placeholder="$t('report.table.unit')"
						class="short" />
				</td>
				<td :data-tooltip="insert.resource.unit
					? $t('tooltip.unit_in', { unit: insert.resource.unit })
					: $t('tooltip.require.unit')">
					<input
						:disabled="!insert.resource.unit"
						v-model.number="insert.qty"
						:placeholder="insert.resource.unit
							? insert.resource.unit
							: $t('report.table.qty')"
						type="number"
						min="0"
						step="0.1"/>
				</td>
			</template>
		</emissions-table>
		<p v-if="isSkipperVisible">
			<label>
				<input type="checkbox" v-model="skip" :disabled="!isReportEditable" />
				{{ $t('global.tip.skip') }}
			</label>
		</p>
	</section>
</template>

<script>
import StepBlockMixin from '@/mixins/StepBlockMixin';
import EmissionsTable from '@/components/EmissionsTable.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';

export default {
	name: 'StepMaterials',
  keyBlock: 'materials',
	mixins: [StepBlockMixin],
	components: { EmissionsTable, DropdownSelect },
	data() {
		return { units: ['m3', 'kg', 'litres'] };
	},
};
</script>
