<template lang="html">
	<article class="step">
		<h2>{{ $t(`${sector}.a3.title`) }}</h2>
		<p>{{ $t(`${sector}.a3.desc`) }}</p>
		<step-fuels :sector="sector" abast="a3" />
		<step-energy :sector="sector" abast="a3" />
		<step-gases :sector="sector" abast="a3" />
		<step-workers :sector="sector" abast="a3" />
		<step-external :sector="sector" abast="a3" />
		<step-materials :sector="sector" abast="a3" />
		<step-waste :sector="sector" abast="a3" />
		<step-water :sector="sector" abast="a3" />
	</article>
</template>

<script>
import StepMixin from '@/mixins/StepMixin';
import StepFuels from '../blocks/StepFuels.vue';
import StepEnergy from '../blocks/StepEnergy.vue';
import StepGases from '../blocks/StepGases.vue';
import StepWorkers from '../blocks/StepWorkers.vue';
import StepExternal from '../blocks/StepExternal.vue';
import StepMaterials from '../blocks/StepMaterials.vue';
import StepWaste from '../blocks/StepWaste.vue';
import StepWater from '../blocks/StepWater.vue';

export default {
	name: 'StepA3',
	mixins: [StepMixin],
	components: {
		StepFuels,
		StepEnergy,
		StepGases,
		StepWorkers,
		StepExternal,
		StepMaterials,
		StepWaste,
		StepWater,
	},
	mounted() { this.$emit('validate', true); },
};
</script>
